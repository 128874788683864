<template>
    <div>
        <div class="broadside" v-if="allbol">
            <div class="sidelist stopbtn">
                <div class="sidepic">
                <img src="../../src/assets/top.png" alt="">
                </div>
                <p>返回顶部</p>
            </div>
            <div class="sidelist livewei" @click="likeourshow" @mouseover="liveweibtn" @mouseout="liveweibtnclose">
                <div class="sidepic">
                <img src="../../src/assets/live.png" alt="">
                </div>
                <p>关注我们</p>
            </div>
            <div class="sidelist" @click="appbtn">
                <div class="sidepic">
                <img src="../../src/assets/upload.png" alt="">
                </div>
                <p>下载体验</p>
            </div>
        </div>
        <div class="likeour" :class="liveweicssbol?'liveshow':'liveclose'" v-show="liveweibol">
        <div class="likeourbox">
            <div class="jian"></div>
            <div class="ourtop">
                <div class="topleft">
                <img src="../../src/assets/WeChat1.png" alt="">
                </div>
                <div class="topright">
                <p>关注微信公众号</p>
                <p>获取最新分享内容</p>
                </div>
            </div>
            <div class="erwei">
                <img src="../../src/assets/gonger.jpg" alt="">
            </div>
            <p class="likedec">微信扫一扫关注</p>
            <p class="likedec">或搜索关注:USeeHel攸视</p>
        </div>
        </div>
        <div class="appupload" :class="appcssbol?'appshow':'appclose'" v-show="appweibol">
            <div class="applist" @click="getdownload(2)">
            <div class="pic">
                <img src="../assets/andpic.png" alt="">
            </div>
            <p>安卓下载</p>
            </div>
            <div class="lin"></div>
            <div class="applist" >
            <a :href="iosUrl" target="blank">
                <div class="pic">
                <img src="../assets/apple.png" alt="">
                </div>
                <p>IOS下载</p>
            </a>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "broadside",
    props:{
      typeApk:{
        type:Number,
        default:1
      }
    },
    data() {
        return {
            liveweibol:false,
            liveweicssbol:false,
            appweibol:false,
            appcssbol:false,
            iosUrl:''
        };
    },
    methods: {
      // 侧边栏关注的显示与隐藏
        liveweibtn(){
            this.liveweicssbol = true
            setTimeout(()=>{
                this.liveweibol = true
            },300)
        },
        liveweibtnclose(){
            this.liveweicssbol = false
            setTimeout(()=>{
                this.liveweibol = false
            },300)
        },
        likeourshow(){
            this.liveweibol = !this.liveweibol
        },
        // 侧边栏下载的显示与隐藏
        appbtn(){
            this.appcssbol = !this.appcssbol
            setTimeout(()=>{
                this.appweibol = !this.appweibol
            },300)
        },
        // useehel和攸视竞技的安卓下载安装包
        getdownload(e){
          if(this.typeApk == 1){
            this.$api.get('api/package',{type:e}).then(res=>{
                const url = res.data.apk_url
                const link = document.createElement('a')        
                link.href = url
                link.click()
            })
          }else if(this.typeApk == 2){
            this.$api.get('/game/package',{type:e}).then(res=>{
                const url = res.data.apk_url
                const link = document.createElement('a')        
                link.href = url
                link.click()
            })
          }
        }
    },
    mounted(){
      // 侧边栏判断是useehel还是攸视竞技
      if(this.typeApk == 1){
        this.iosUrl = 'http://apps.apple.com/cn/app/id6449661704'
      }else if(this.typeApk == 2){
        this.iosUrl = 'http://apps.apple.com/cn/app/id6458042137'
      }
      // 侧边栏返回顶部的显示与隐藏
        if(this.allbol){
            var topbtn = document.querySelector('.stopbtn')
            window.onscroll = ()=>{
                if(document.documentElement.scrollTop >= window.innerHeight){
                topbtn.style.display = "flex"
                }else{
                topbtn.style.display = "none"
                }
            }
            topbtn.onclick = ()=>{
                document.body.scrollIntoView({
                behavior:'smooth',
                block:'start'
                })
            }
        }
    },
};
</script>
  
<style lang="scss" scoped>
img{
    width: 100%;
    height: 100%;
}
a{
  font-size: 5px;
  color: #fff;
  text-decoration: none;
}
.broadside{
  position: fixed;
  right: 2%;
  bottom: 10%;
  .sidelist{
    width: 25px;
    border-radius: 2px;
    height: 25px;
    margin-top: 4px;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .sidepic{
      width: 9px;
      height: 9px;
    }
    p{
      margin-top: 2px;
      font-size: 5px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .stopbtn{
    display: none;
  }
}
.likeour{
  position: fixed;
  right: 50px;
  bottom: 12%;
  z-index: 1500;
  .likeourbox{
    position: relative;
    background-color: #fff;
    padding: 8px 5px;
  }
  .jian{
    position: absolute;
    right: -3px;
    top: 40%;
    width: 6px;
    height: 6px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  .ourtop{
    display: flex;
    align-items: center;
    .topleft{
      width: 8px;
      height: 8px;
      overflow: hidden;
      border-radius: 15px;
    }
    .topright{
      font-size: 5px;
      font-weight: bold;
      text-align: center;
      margin-left: 3px;
      color: rgba(0, 0, 0, 0.9);
      p:nth-child(2){
        font-size: 5px;
        font-weight: normal;
      }
    }
  }
  .erwei{
    width: 30px;
    height: 30px;
    margin: 6px auto;
  }
  .likedec{
    text-align: center;
    font-size: 5px;
    color: rgba(0, 0, 0, 0.7);
  }
}
.liveshow{
  animation: show .3s linear;
  @keyframes show {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
}
.liveclose{
  animation: close .3s linear;
  @keyframes close {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
}
.appupload{
  width: 80px;
  height: 20px;
  padding: 0 2px;
  border-radius: 10px;
  position: fixed;
  right: 50px;
  bottom: 10%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .lin{
    height: 60%;
    width: 0.5px;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .applist{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a{
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      p{
        font-size: 5px;
      }
    }
    .pic{
      width: 8px;
      height: 8px;
    }
    p{
      font-size: 5px;
    }
  }
}
.appshow{
  animation: appshow .3s linear;
  @keyframes appshow {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
}
.appclose{
  animation: appclose .3s linear;
  @keyframes appclose {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
}

.download{
  width: 100%;
  position: absolute;
  bottom: 10%;
  left: 0;
  .downbox{
    position: relative;
    width: 60%;
    margin: 0 auto;
  }
  .erpic{
    margin: 0 auto;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
  }
  .cont{
    width: 100%;
    .downbtn{
      width: 65%;
      height:80px;
      border-radius: 100px;
      margin: 30px auto 0;
      background-color: #fff;
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        font-size: 26px;
      }
      div{
        width: 45px;
        height: 45px;
        margin-right: 10px;
      }
    }
    .downbtn:nth-child(2){
      div{
        margin-top: -0.2rem;
      }
    }
  }
}
</style>