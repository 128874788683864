<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
      app: null,
      screenWidth: document.body.clientWidth,
    };
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        console.log(val);
        if (val <= 900) {
          Vue.prototype.allbol = false;
          console.log("屏幕宽度小于1550px", this.allbol);
        } else {
          Vue.prototype.allbol = true;
          console.log("屏幕宽度大于1550px", this.allbol);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.app = document.getElementById("app");
    var _this = this;
    window.onresize = () => {
      return (() => {
        _this.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: oppo;
  src: url("https://ljjpro-1254421192.cos.ap-shanghai.myqcloud.com/file/useehel/static/font/OPlusSans3-Regular.ttf");
}
* {
  font-family: oppo !important;
}
</style>
