<template>
    <div class="popupbox" :class="popcssbol?'popshow':'popclose'" v-show="popweibol">
      <div class="popup">
        <div class="popuptit">
          <p>USeeHel攸视官方微信二维码</p>
          <div @click="closepop">
            <img src="../../src/assets/close.png" alt="">
          </div>
        </div>
        <div class="popupcon">
          <div class="poper">
            <img src="../../src/assets/gonger.jpg" alt="">
          </div>
          <div class="poptext">
            <p>USeeHel攸视官方微信</p>
            <div class="equity">
              <div class="equitylist" v-for="(item,index) in equitylist" :key="index">
                <div>
                  <img :src="item.pic" alt="">
                </div>
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: "wechat",
    data() {
      return {
        equitylist:[
            {
            name:'人工在线客服',
            pic:require('../assets/kefu.png')
            },
            {
            name:'获取新品资讯',
            pic:require('../assets/info.png')
            },
            {
            name:'最新福利发送',
            pic:require('../assets/money.png')
            }
        ],
        popcssbol:false,
        popweibol:false,
     };
    },
    methods: {
        showpop(){
            console.log(this.popcssbol);
            this.popcssbol = true
            setTimeout(()=>{
                this.popweibol = true
            },300)
        },
        closepop(){
            this.popcssbol = false
            setTimeout(()=>{
                this.popweibol = false
            },300)
        },
    },
  };
  </script>
  
<style lang="scss" scoped>
img{
    width: 100%;
    height: 100%;
}
  .popupbox{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup{
    width: 200px;
    height: 140px;
    background-color: #fff;
    .popuptit{
      height: 20px;
      width: 100%;
      padding: 0 2%;
      background-color: #f0f0f0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 6px;
      color: #666;
      div{
        width: 5px;
        height: 5px;
      }
    }
    .popupcon{
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      .poper{
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      .poptext{
        color: rgba(0, 0, 0, 0.85);
        p{
          font-size: 8px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .equity{
          .equitylist{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            div{
              width: 10px;
              height: 10px;
              margin-right: 10px;
            }
            span{
              display: block;
              font-size: 6px;
              font-weight: 800;
              color: rgba(0, 0, 0, 0.75);
            }
          }
          .equitylist:nth-child(3){
            div{
              transform: scale(0.85);
            }
          }
          .equitylist:nth-child(2){
            div{
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}
.popshow{
  animation: popshow .3s linear;
  @keyframes popshow {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
}
.popclose{
  animation: popclose .3s linear;
  @keyframes popclose {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
}
  </style>