<template>
  <div>
    <div class="footer" v-if="allbol">
      <div class="footerbox">
        <div class="footcont">
          <div class="footbox">
            <p class="foottitle">
              <span>联系我们</span>
              <span>Contact</span>
            </p>
            <div class="footLeft">
              <div class="type">
                <div class="list">
                  <span>地址</span>：<span>浙江省杭州市余杭区时代未来之城</span>
                </div>
              </div>
              <div class="contacttop">
                <div
                  class="type"
                  v-for="(item, index) in contactlist"
                  :key="index"
                >
                  <div class="list" v-for="(e, i) in item" :key="i">
                    <span>{{ e.name }}</span
                    >：<span>{{ e.val }}</span>
                  </div>
                </div>
              </div>
              <div class="live">
                <p>关注我们：</p>
                <div class="livepicbox">
                  <div
                    class="livelist"
                    v-for="(item, index) in livelist"
                    :key="index"
                    @click="showpop(item, index)"
                  >
                    <img :src="item" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="map">
            <img :src="`${imgHttp}/map.png`" alt="" />
          </div>
        </div>
      </div>
      <div class="bom">
        <p @click="servetext(1)">用户服务协议</p>
        <span>｜</span>
        <p @click="servetext(2)">隐私政策</p>
        <span>｜</span>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank"
            >浙ICP备2023013600号-1</a
          >
        </p>
        <span>｜</span>
        <p @click="open">营业执照</p>
      </div>
    </div>
    <div class="hfooter" v-else>
      <div class="hcontext">
        <div>
          <p @click="servetext(1)">用户服务协议</p>
          <span>｜</span>
          <p @click="servetext(2)">隐私政策</p>
          <span>｜</span>
          <p @click="open">营业执照</p>
        </div>
        <p>
          公司名称：杭州佳跑科技有限公司｜地址： 浙江省杭州市余杭区时代未来之城
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank"
            >浙ICP备2023013600号-1</a
          >
        </p>
      </div>
    </div>
    <models ref="model"></models>
    <wechat ref="wechat"></wechat>
  </div>
</template>

<script>
import models from "./Models.vue";
import wechat from "./weChat.vue";

export default {
  name: "pagefoot",
  components: {
    models,
    wechat,
  },
  props: {
    typeUsee: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      contactlist: [
        [
          {
            name: "邮编",
            val: " 311100",
          },
          {
            name: "电话",
            val: " 0571 87116292",
          },
        ],
        [
          {
            name: "手机",
            val: "18072940199",
          },
          {
            name: "公司名称",
            val: "杭州佳跑科技有限公司",
          },
        ],
      ],
      livelist: [require("../../src/assets/WeChat1.png")],
    };
  },
  methods: {
    showpop() {
      this.$refs.wechat.showpop();
    },
    open() {
      this.$refs.model.open();
    },
    // 点击底部服务协议或隐私政策  去往不同的界面
    servetext(e) {
      if (this.typeUsee == 1) {
        this.$router.push({ path: "about", query: { type: e } });
      } else if (this.typeUsee == 2) {
        var game;
        if (e == 1) {
          game = 4;
        } else if (e == 2) {
          game = 3;
        }
        this.$router.push({ path: "about", query: { type: game } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
a {
  font-size: 5px;
  color: #fff;
  text-decoration: none;
}
.footer {
  width: 100%;
  background-color: #fff;
  padding: 4% 0 0;
  .footerbox {
    width: 80%;
    min-width: 500px;
    margin: 0 auto;
    background-color: #fff;

    .footcont {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-size: 6px;
      color: #999;
      .footbox {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        .foottitle {
          width: 100%;
          text-align: left;
          color: #333;
          font-size: 8px;
          margin-bottom: 10px;
          span:nth-child(2) {
            color: #999;
            font-size: 7px;
            margin-left: 10px;
          }
        }
      }
      .footLeft {
        width: 100%;
      }
      .contacttop {
        display: flex;
        .type {
          width: 40%;
          .list {
            margin-top: 5px;
          }
        }
      }
      .live {
        margin-top: 10px;
        display: flex;
        .livepicbox {
          display: flex;
          align-items: center;
          .livelist {
            width: 8px;
            height: 8px;
            margin-left: 2px;
            img {
              display: block;
            }
          }
        }
      }
      .map {
        width: 35%;
      }
    }
  }
  .bom {
    margin-top: 5%;
    width: 100%;
    height: 5vh;
    min-height: 10px;
    font-size: 5px;
    color: #fff;
    background-color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.hfooter {
  background-color: #000;
  color: #f0f0f0;
  // margin-top: -1px;
  width: 100%;
  font-size: 15px;
  a {
    font-size: 15px;
  }
  .hcontext {
    width: 80%;
    border-top: 2px solid #fff;
    margin: 0 auto;
    padding: 5% 0;
    text-align: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
