<template>
  <div class="models" v-if="shows" @click="close">
    <div class="modelbox">
      <div class="mobox">
        <div class="del" @click="close">
          <img src="../../src/assets/close.png" alt="">
        </div>
        <img :src="`${imgHttp}/yingye.png`" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "models",
  data() {
    return {
      models: [],
      shows: false,
    };
  },
  methods: {
    close() {
      this.shows = false;
    },
    open() {
      this.shows = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.models {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  &:hover {
    transition: all 0.3s initial;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4) inset;
  }
  .modelbox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .mobox{
      position: relative;
    }
    .del{
      width: 10px;
      height: 10px;
      position: absolute;
      right: 2%;
      top: 2%;
      img{
        width: 100%;
        height: 100%;
      }
    }
    img {
      max-width: 70vw;
    }
    // width: 100%;
    // height: 100%;
  }
}
@media screen and (max-width:900px){
  .del{
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    right: 2% !important;
    top: 2% !important;
    img{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>