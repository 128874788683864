<template>
  <div class="top">
    <div class="maintop" v-if="allbol">
      <div class="logopic">
        <img src="../../src/assets/logo.png" alt="" />
      </div>
      <div class="mainRight">
        <div
          v-for="(item, index) in drawerItem"
          :key="index"
          class="game"
          @click="navigate(item.path)"
          :class="getClass(index)"
        >
          <p>{{ item.name }}</p>
          <div v-show="switchSta === index"></div>
        </div>
        <div class="call" @click="gobottom">联系我们</div>
      </div>
    </div>
    <div class="mainPtop" v-else>
      <div class="mainLogo">
        <img src="../../src/assets/logo.png" alt="" />
      </div>
      <div class="gameBtn" @click="drawer = true">
        <i class="el-icon-s-unfold"></i>
      </div>
    </div>
    <div :style="{ height: allbol ? '8vh' : '', background: '#000' }"></div>
    <el-drawer
      title="我是标题"
      :with-header="false"
      :visible.sync="drawer"
      direction="ltr"
      size="70%"
    >
      <div class="drawer_content">
        <template v-for="(item, index) in drawerItem">
          <div
            class="item"
            :class="switchSta === index ? 'active' : ''"
            @click="toPath(item.path)"
            :key="item.id"
          >
            <div>
              <span>{{ item.name }}</span>
              <div class="line" v-show="switchSta === index"></div>
            </div>
          </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "headtop",
  props: {
    switchSta: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gameOruseehel: true,
      drawer: false,
      drawerItem: [
        { id: 1, name: "首页", path: "/" },
        { id: 2, name: "UseeHel APP", path: "useehel" },
        { id: 3, name: "攸视竞技", path: "game" },
      ],
    };
  },
  methods: {
    // 切换useehel和攸视竞技
    navigate(path) {
      this.switch(); // 处理状态切换
      this.$router.push({ path }).catch((err) => {
        console.error("Navigation error:", err);
      });
    },

    // 触发事件方法
    gobottom() {
      this.$emit("gobottom"); // 触发事件
    },

    // 获取当前活动状态
    getClass(index) {
      return this.switchSta === index ? "gameSize" : "";
    },
    toPath(e) {
      this.$router.push({ path: `${e}` });
    },
    switch(e) {
      this.$emit("change", e);
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
.maintop {
  background-color: rgba(0, 0, 0, 1);
  // background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  padding: 0 2%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .logopic {
    width: 15%;
    height: 8vh;
    display: flex;
    align-items: center;
    img {
      height: 12px;
    }
  }
  .mainRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .call {
    margin-left: 30px;
    width: 40px;
    height: 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #fff;
    font-size: 6px;
  }
  .proint {
    margin: 0 6px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #fff;
  }
  .game {
    font-size: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-color: #da0f0f;
    padding: 0 5px;
  }
  .gameSize {
    font-size: 10px;
    div {
      height: 1px;
      width: 50%;
      border-radius: 1px;
      background-color: #fff;
      animation: line 0.2s linear;
      @keyframes line {
        0% {
          width: 0;
        }
        100% {
          width: 50%;
        }
      }
    }
  }
}
.mainPtop {
  position: fixed;
  z-index: 200;
  height: 100px;
  width: 100%;
  padding: 0 30px;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mainLogo {
    width: 240px;
    display: flex;
    align-items: center;
  }
  .gameBtn {
    font-size: 40px;
    color: #fff;
    margin-left: 10px;
  }
  .switch {
    width: 50px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    div {
      width: 46px;
      height: 1px;
      background-color: #fff;
    }
  }
  .switchClose {
    div:nth-child(1) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    div:nth-child(2) {
      display: none;
    }
    div:nth-last-child(1) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
.mainSwitch {
  width: 150px !important;
}
.gameSwitch {
  font-size: 40px !important;
}
.drawer_content {
  padding: 100px 20px 0;
  color: #fff;
  font-size: 30px;

  .item {
    padding: 5px 5px 15px 10px;
    margin: 5px;
    background: rgba(255, 255, 255, 0.1);

    div {
      display: inline-block;
      position: relative;

      .line {
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 100%;
        height: 5px;
        background: #fff;
      }
    }
  }

  .active {
    font-size: 40px;
    font-weight: 600;
  }
}

::v-deep .el-drawer__body {
  background: #000;
}
</style>
