var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.allbol)?_c('div',{staticClass:"broadside"},[_vm._m(0),_c('div',{staticClass:"sidelist livewei",on:{"click":_vm.likeourshow,"mouseover":_vm.liveweibtn,"mouseout":_vm.liveweibtnclose}},[_vm._m(1),_c('p',[_vm._v("关注我们")])]),_c('div',{staticClass:"sidelist",on:{"click":_vm.appbtn}},[_vm._m(2),_c('p',[_vm._v("下载体验")])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.liveweibol),expression:"liveweibol"}],staticClass:"likeour",class:_vm.liveweicssbol?'liveshow':'liveclose'},[_vm._m(3)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appweibol),expression:"appweibol"}],staticClass:"appupload",class:_vm.appcssbol?'appshow':'appclose'},[_c('div',{staticClass:"applist",on:{"click":function($event){return _vm.getdownload(2)}}},[_vm._m(4),_c('p',[_vm._v("安卓下载")])]),_c('div',{staticClass:"lin"}),_c('div',{staticClass:"applist"},[_c('a',{attrs:{"href":_vm.iosUrl,"target":"blank"}},[_vm._m(5),_c('p',[_vm._v("IOS下载")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidelist stopbtn"},[_c('div',{staticClass:"sidepic"},[_c('img',{attrs:{"src":require("../../src/assets/top.png"),"alt":""}})]),_c('p',[_vm._v("返回顶部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepic"},[_c('img',{attrs:{"src":require("../../src/assets/live.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepic"},[_c('img',{attrs:{"src":require("../../src/assets/upload.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"likeourbox"},[_c('div',{staticClass:"jian"}),_c('div',{staticClass:"ourtop"},[_c('div',{staticClass:"topleft"},[_c('img',{attrs:{"src":require("../../src/assets/WeChat1.png"),"alt":""}})]),_c('div',{staticClass:"topright"},[_c('p',[_vm._v("关注微信公众号")]),_c('p',[_vm._v("获取最新分享内容")])])]),_c('div',{staticClass:"erwei"},[_c('img',{attrs:{"src":require("../../src/assets/gonger.jpg"),"alt":""}})]),_c('p',{staticClass:"likedec"},[_vm._v("微信扫一扫关注")]),_c('p',{staticClass:"likedec"},[_vm._v("或搜索关注:USeeHel攸视")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/andpic.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/apple.png"),"alt":""}})])
}]

export { render, staticRenderFns }