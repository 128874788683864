import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: function () {
      return import('../views/index.vue')
    }
  },
  {
    path: '/useehel',
    name: 'useehel',
    component: function () {
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/game',
    name: 'game',
    component: function () {
      return import('../views/gameView.vue')
    }
  },
  {
    path: '/aisport',
    name: 'aisport',
    component: function () {
      return import('../views/aisportApp.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
